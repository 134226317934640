<template>
  <vue3VideoPlay
      class="video"
      :src="src"
      @play="onPlay"
      @pause="onPause"
      @timeupdate="onTimeupdate"
      @canplay="onCanplay" />
</template>

<script setup>
import {onMounted, toRef} from "vue";

const props = defineProps({
  url: String,
})

const src = toRef(props, 'url')

const onPlay = (ev) => {}
const onPause = (ev) => {}

const onTimeupdate = (ev) => {}
const onCanplay = (ev) => {}
onMounted(() => {})
</script>

<style lang="less">
.video{
  width: 100%;
  height: 100%;
}
</style>
