<template>
  <div class="preview-body">
    <vue-office-pdf :src="url"/>
  </div>
</template>

<script setup>
import VueOfficePdf from '@vue-office/pdf'
import {toRef} from "vue";

const props = defineProps({
  url: String
})
const url = toRef(props,"url")

</script>

<style lang="less">

</style>
