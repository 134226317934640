import Menu from '../store/modules/Menu.js'

import { createStore } from 'vuex'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Menu
  }
})
