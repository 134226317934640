<template>
  <div class="wrapper">
    <div class="content">
      <div class="tittle-wrapper">
        <div class="page-tittle">重点新闻</div>
        <div class="tittle">
          {{ newsInfo.tittle}}
        </div>
        <div class="date">
          {{ newsInfo.date }}
        </div>
      </div>
      <div class="news" v-html="newsContent.content">
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsByKey} from "@/mock/news";
import {getNewsContentByKey} from "@/mock/newsContent";

export default {
  name: "Winner",
  components: {},
  data() {
    return {
      newsInfo: {
        tittle: "",
        date: "",
        image: "",
        key: ""
      },
      newsContent: {
        key: "",
        content: ""
      },
    }
  },
  methods: {},
  created() {
    let newsKey = localStorage.getItem('newsKey')
    this.newsInfo = getNewsByKey(newsKey)
    this.newsContent = getNewsContentByKey(this.newsInfo.key)
  },
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  }
}
</script>

<style lang="less">
.wrapper{
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  .content{
    width: 1090px;
    margin-bottom: 147px;
    .tittle-wrapper{
      width: 1090px;
      height: 275px;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 1090px;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 1090px;
        height: 82px;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 1;
      }
      .date{
        width: 1090px;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img{
        width: 409px;
        height: 287px;
        opacity: 1;
      }
      .first-win-img{
        background: url('@/assets/images/news/detail/1.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .second-win-img{
        background: url('@/assets/images/news/detail/2.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .second-img-wrapper{
        display: flex;
        flex-direction: row;
        padding-left: 245px;
        .third-img{
          width: 214px;
          height: 245px;
          opacity: 1;
          margin-right: 72px;
          background: url('@/assets/images/news/detail/3.png') no-repeat;
          background-size: 100% 100%;
          z-index: 0;
        }
        .fourth-img{
          width: 315px;
          height: 238px;
          opacity: 1;

          background: url('@/assets/images/news/detail/4.png') no-repeat;
          background-size: 100% 100%;

          z-index: 1;
        }
      }
      p{
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgb(250, 250, 252);
      }
    }
  }
}
</style>
