<template>
  <div class="phoneContent">
    <el-main>
      <!-- 轮播图 -->
      <el-card class="slideshow_card">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item key="1">
            <img class="bgImage" alt="" src="@/assets/images/home/slideshowBg.png">
            <!-- <div class="contentDiv">
              <div class="titleDiv">
                <span class="titleSpan">天行 T3 可编程逻辑控制器</span>
              </div>
              <div class="textDiv">
                <span class="textSpan">天行启势力 铸大国重器</span>
              </div>
              <div class="textDiv2">
                <span class="textSpan">掌握工控技术 突破核心技术</span>
              </div>
              <div class="btnDiv"> -->
                <!-- "了解更多"按钮 -->
                <!-- <div class="learnMoreBtn btn-wrap">
                  <div class="border-bg"></div>
                  <el-button class="button">了解更多</el-button>
                </div>
              </div>
            </div>
            <img class="image" alt="" src="@/assets/images/slideshowImg1.png"> -->
          </el-carousel-item>
        </el-carousel>
      </el-card>
      <!-- 全栈自主可控PLC -->
      <el-card class="controllable_card">
        <template #header>
          <div class="card-header">
            <span>全栈自主可控PLC</span>
          </div>
        </template>
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item name="1">
            <template #title>
              <el-icon class="collapse-icon"><Right /></el-icon>
              <img class="bottom_arrow_img" alt="" src="@/assets/images/home/bottom_arrow.png">
              <span class="collapse-title">硬件设计</span>
            </template>
            <div class="contentDiv">
              <div class="textDiv">
                <span class="textSpan">集工业特性及新颖时尚于一体，从芯片选型、电路布局到硬件模块的结构构造，确保硬件底层的高可靠性和安全性。</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template #title>
              <el-icon class="collapse-icon"><Right /></el-icon>
              <img class="bottom_arrow_img" alt="" src="@/assets/images/home/bottom_arrow.png">
              <span class="collapse-title">实时操作系统</span>
            </template>
            <div class="contentDiv">
              <div class="textDiv">
                <span class="textSpan">嵌入式操作系统具备高实时性响应能力，对工业现场各类数据采集、控制指令下达等任务进行高效处理，能有效适配 PLC 硬件架构并抵御外部干扰与网络攻击。</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template #title>
              <el-icon class="collapse-icon"><Right /></el-icon>
              <img class="bottom_arrow_img" alt="" src="@/assets/images/home/bottom_arrow.png">
              <span class="collapse-title">PLC运行时</span>
            </template>
            <div class="contentDiv">
              <div class="textDiv">
                <span class="textSpan">PLC运行的核心引擎，能够高效地按照优先级执行程序，并且对程序进行严格监控，确保系统按照预定逻辑及时序运行，提高工业控制过程的精确性及稳定性。</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template #title>
              <el-icon class="collapse-icon"><Right /></el-icon>
              <img class="bottom_arrow_img" alt="" src="@/assets/images/home/bottom_arrow.png">
              <span class="collapse-title">IEC-61131-3编译器</span>
            </template>
            <div class="contentDiv">
              <div class="textDiv">
                <span class="textSpan">基于IEC61131-3标准，支持通用LD,ST,SFC,CFC等编程语言，提供语法检査和错误提示的功能，帮助用户高效、准确地完成程序开发调试。</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <template #title>
              <el-icon class="collapse-icon"><Right /></el-icon>
              <img class="bottom_arrow_img" alt="" src="@/assets/images/home/bottom_arrow.png">
              <span class="collapse-title">组态软件</span>
            </template>
            <div class="contentDiv">
              <div class="textDiv">
                <span class="textSpan">图形化设备管理和组态界面，并且能够直观地设置IO设备参数、通信协议和数据映射关系。
                  支持梯形图，ST，SFC，CFC等的编程环境。具有强大的在线监控，硬件诊断等功能，助力实现PLC与整个工业自动化系统的无缝对接。</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <!-- 解决方案/案例 -->
      <!-- <el-card class="solution_card">
        <template #header>
          <div class="card-header">
            <span>服务与支持</span>
          </div>
        </template>
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item key="1">
            <div class="taigangDiv">
              <div class="contentDiv">
                <div class="titleDiv">
                  <span class="titleSpan">太钢冷轧机组</span>
                </div>
                <div class="textDiv">
                  <ul>
                    <li>架构企业数字化顶层设计和搭建数字化统一底座</li>
                    <li>一键部署：微搭产品包含云资源，无需单独采购</li>
                    <li>降本增效：丰富免费的模板快速拖拽构建</li>
                  </ul>
                </div>
                <div class="taigangBtnDiv"> -->
                  <!-- "立即咨询"按钮 -->
                  <!-- <div class="consultNowBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">立即咨询</el-button>
                  </div> -->
                  <!-- "了解更多"按钮 -->
                  <!-- <div class="learnMoreBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">了解更多</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item key="2">
            <div class="baogangDiv">
              <div class="contentDiv">
                <div class="titleDiv">
                  <span class="titleSpan">宝钢股份</span>
                </div>
                <div class="textDiv">
                  <span class="textSpan">新基建，助力金融机构夯实技术底座</span>
                </div>
                <div class="baogangBtnDiv"> -->
                  <!-- "立即咨询"按钮 -->
                  <!-- <div class="consultNowBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">立即咨询</el-button>
                  </div> -->
                  <!-- "了解更多"按钮 -->
                  <!-- <div class="learnMoreBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">了解更多</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item key="3">
            <div class="hongwangDiv">
              <div class="contentDiv">
                <div class="titleDiv">
                  <span class="titleSpan">广西宏旺</span>
                </div>
                <div class="textDiv">
                  <span class="textSpan">新连接，助力金融机构与用户之间的高效触达</span>
                </div>
                <div class="hongwangBtnDiv"> -->
                  <!-- "立即咨询"按钮 -->
                  <!-- <div class="consultNowBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">立即咨询</el-button>
                  </div> -->
                  <!-- "了解更多"按钮 -->
                  <!-- <div class="learnMoreBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">了解更多</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item key="4">
            <div class="guigangDiv">
              <div class="contentDiv">
                <div class="titleDiv">
                  <span class="titleSpan">新钢硅钢机组</span>
                </div>
                <div class="textDiv">
                  <span class="textSpan">新服务，助力金融机构打造面向垂直场景的创新解决方案</span>
                </div>
                <div class="guigangBtnDiv"> -->
                  <!-- "立即咨询"按钮 -->
                  <!-- <div class="consultNowBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">立即咨询</el-button>
                  </div> -->
                  <!-- "了解更多"按钮 -->
                  <!-- <div class="learnMoreBtn btn-wrap">
                    <div class="border-bg"></div>
                    <el-button class="button">了解更多</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item> 
        </el-carousel>
      </el-card> -->

      <!-- 新闻与动态 -->
      <el-card class="newsTrends_card">
        <template #header>
          <div class="card-header">
            <span>新闻与动态</span>
          </div>
        </template>
        <el-carousel arrow="always">
          <el-carousel-item :key="index" v-for="(item,index) in newsDisplay">
            <div class="newsDiv" @click="gotoDetails(index)">
              <img class="newsImg" alt="" :src="item.image">
              <div class="contentDiv">
                <div class="titleDiv">
                  <span class="titleSpan">{{item.tittle}}</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-card>
      <!-- 联系我们 -->
      <el-card class="serviceSupport_card">
        <template #header>
          <div class="card-header">
            <span>联系我们</span>
          </div>
        </template>
        <el-row class="topRow">
          <el-col :span="10">
            <div class="titleDiv">
              <span class="titleSpan">热线电话：</span>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="textDiv">
              <span class="textSpan">400-821-0860</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="bottomRow">
          <el-col :span="10">
            <div class="titleDiv">
              <span class="titleSpan">产品邮箱：</span>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="textDiv">
              <span class="textSpan">baosky@baosight.com</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-main>
  </div>
</template>

<script>
import {getAll} from '@/mock/news'
//公共图标按钮
import { Right } from '@element-plus/icons-vue'

export default {
  name: 'PhoneContent',
  components: {
    Right
  },
  data() {
    return {
      activeNames: '1',
      news: [],
      newsDisplay: [],
    }
  },
  methods: {
    handleChange(val) {
      //console.log(val);
    },
    fetchData(){
      this.news = getAll()
      if(this.news.length <= 9){
        this.newsDisplay = this.news;
      }else {
        this.newsDisplay = this.news.slice(0,9);
      }
    },
    gotoDetails(index){
      localStorage.setItem("newsKey",this.news[index].key);
      this.$router.push({
        name: "phoneNewsDetail",
        params: {
          newsKey: this.news[index].key
        }
      })
    }
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style lang="less">
@import "@/assets/css/icon/style.css";
@import "@/assets/css/publicStyle.css";
.phoneContent{
  // 轮播图卡片
  .slideshow_card{
    height: 190px;
    background: none;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__body{
      // 走马灯
      .el-carousel{
        height: 200px;
        .el-carousel__container{
          .el-carousel__item{
            height: 190px;
            .bgImage{
              width: 100%;
              height: auto;
              z-index: -1;
              position: fixed;
              top: 0;
              left: 0;
            }
            // .contentDiv{
            //   width: 100%;
            //   height: 105px;
            //   margin-bottom: 10px;
            //   .titleDiv{
            //     width: 100%;
            //     height: 41px;
            //     text-align: center;
            //     margin-top: 19px;
            //     .titleSpan{
            //       font-family: Source Han Sans;
            //       font-size: 28px;
            //       font-weight: bold;
            //       line-height: 41px;
            //       color: #FAFAFC;
            //       white-space: nowrap;
            //     }
            //   }
            //   .textDiv{
            //     margin-top: 12px;
            //   }
            //   .textDiv,.textDiv2{
            //     width: 100%;
            //     height: 26px;
            //     text-align: center;
            //     .textSpan{
            //       font-family: Source Han Sans;
            //       font-size: 18px;
            //       font-weight: 500;
            //       line-height: 26px;
            //       color: #FAFAFC;
            //     }
            //   }
            //   .btnDiv{
            //     width: 100%;
            //     height: 36px;
            //     text-align: center;
            //     margin-top: 16px;
            //     .learnMoreBtn{
            //       margin-left: 47%;
            //     }
            //   }
            // }
          }
        }
        .el-carousel__indicators{
          bottom: 10px;
        }
      }
    }
  }
  // "全栈自主可控PLC"卡片
  .controllable_card{
    height: 470px;
    background: none;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      height: 397px;
      margin-top: 32px;
      .el-collapse{
        --el-collapse-border-color: rgba(250, 250, 252, 0.2);
        --el-collapse-header-bg-color: #22262D;
        --el-collapse-header-text-color:#FAFAFC;
        --el-collapse-header-font-size: 18px;
        border:0px;
        .el-collapse-item{
          &.is-active {
            .el-collapse-item__header{
              border-width: 2px;
              border-style: solid;
              border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 0 0 2 0;
              // 图标按钮
              .collapse-icon{
                display: none;
              }
              .bottom_arrow_img{
                display: block;
                // margin-left: 40%;
              }
              // 标题
              .collapse-title{
                font-family: Source Han Sans;
                font-size: 24px;
                font-weight: bold;
                background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
          }
          .el-collapse-item__header{
            height: 48px;
            // 图标按钮
            .collapse-icon{
              height: 1.5em;
              width: 1.5em;
              line-height: 1.5em;
              // margin-left: 40%;
              svg{
                height: 1.5em;
                width: 1.5em;
              }
            }
            .bottom_arrow_img{
              display: none;
            }
            // 标题
            .collapse-title{
              font-family: Source Han Sans;
              font-size: 18px;
              font-weight: 500;
              color: #FAFAFC;
              float: left;
              margin-left: 10px;
            }
            // 原折叠面板的图标
            .el-collapse-item__arrow{
              display: none;
            }
          }
          .el-collapse-item__wrap{
            //height: 250px;
            //overflow-y: auto;
            background: rgba(29, 29, 31, 0.2);
            .el-collapse-item__content{
              padding-bottom: 10px;
              .contentDiv{
                margin-top: 16px;
                .textDiv{
                  width: 100%;
                  //height: 33px;
                  text-align: left;
                  .textSpan{
                    font-family: Source Han Sans;
                    font-size: 16px;
                    line-height: 23px;
                    color: rgba(250, 250, 252, 0.6);
                  }
                }
              }
              .productImage{
                width: 323px;
                height: 199px;
              }
            }
          }
        }
      }
    }
  }
  // "解决方案/案例"卡片
  .solution_card{
    height: 473px;
    background: #22262D;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      // 走马灯
      .el-carousel{
        height: 432px;
        margin-top: 32px;
        .el-carousel__container{
          height: 400px;
          .el-carousel__item{
            height: 400px;
            .taigangDiv,.baogangDiv,.hongwangDiv,.guigangDiv{
              width: 100%;
              height: 400px;
              border-radius: 10px;
            }
            .taigangDiv{
              background: ~"url(@/assets/images/productCenter/taigang.png)";
              background-size: 100% 100%;
            }
            .baogangDiv{
              background: ~"url(@/assets/images/productCenter/baogang.png)";
              background-size: 100% 100%;
            }
            .hongwangDiv{
              background: ~"url(@/assets/images/productCenter/hongwang.png)";
              background-size: 100% 100%;
            }
            .guigangDiv{
              background: ~"url(@/assets/images/productCenter/guigang.png)";
              background-size: 100% 100%;
            }
            .contentDiv{
              width: 100%;
              padding: 24px 0px;
              .titleDiv{
                width: 100%;
                height: 35px;
                text-align: center;
                .titleSpan{
                  font-family: Source Han Sans;
                  font-size: 24px;
                  font-weight: bold;
                  line-height: 35px;
                  color: #FAFAFC;
                }
              }
              .textDiv{
                width: 100%;
                height: 80px;
                text-align: center;
                margin-top: 28px;
                ul{
                  margin-block-start: 0em;
                  margin-block-end: 0em;
                  padding-inline-start: 60px;
                  li{
                    width: 100%;
                    min-height: 28px;
                    font-family: Source Han Sans;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FAFAFC;
                    text-align: left;
                  }
                }
                .textSpan{
                  font-family: Source Han Sans;
                  font-size: 14px;
                  line-height: 20px;
                  color: #FAFAFC;
                  word-wrap: break-word;
                }
              }
              .taigangBtnDiv,.baogangBtnDiv,.hongwangBtnDiv,.guigangBtnDiv{
                padding-top: 173px;
                padding-left: 80px;
                // "立即咨询"按钮
                .consultNowBtn{
                  float: left;
                }
                // "了解更多"按钮
                .learnMoreBtn{
                  float: left;
                  margin-left: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  // "新闻与动态"卡片
  .newsTrends_card{
    height: 420px;
    background: none;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 32px;
      // 走马灯
      .el-carousel{
        height: 347px;
        .el-carousel__container{
          height: 347px;
          .el-carousel__item{
            .newsDiv{
              width: 100%;
              height: 347px;
              border-radius: 10px;
            }
            .newsImg{
              width: 100%;
              height: auto;
            }
            .contentDiv{
              width: 100%;
              margin-top: 10px;
              .titleDiv{
                height: 70px;
                padding: 0px 60px;
                text-align: center;
                .titleSpan{
                  font-family: Source Han Sans;
                  font-size: 16px;
                  line-height: 25px;
                  color: #FAFAFC;
                }
              }
            }
          }
        }
        .el-carousel__indicators{
          display: none;
        }
      }
    }
  }
  // "联系我们"卡片
  .serviceSupport_card{
    height: 140px;
    background: none;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 32px;
      .topRow,.bottomRow{
        width: 100%;
        height: 30px;
        .titleDiv{
          height: 100%;
          text-align: right;
          .titleSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #FAFAFC;
          }
        }
        .textDiv{
          height: 100%;
          text-align: left;
          margin-left: 10px;
          .textSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #FAFAFC;
          }
        }
      }
    }
  }
}
</style>