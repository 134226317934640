const newsData = [
  {
    key: "202409200",
    tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发",
    date: "2024年9月20日",
    image: require('@/assets/images/news/first.png')
      // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
  },
  {
    key: "202411260",
    tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    date: "2024年11月26日",
    image: require('@/assets/images/news/detail/4.png')
  },
  {
    key: "202411261",
    tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    date: "2024年11月26日",
    image: require('@/assets/images/news/detail/3-1.png')
  },


    // {
    //     key: "202409200",
    //     tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发1",
    //     date: "2024年9月20日",
    //     image: require('@/assets/images/news/first.png')
    //     // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
    // },
    // {
    //     key: "202411260",
    //     tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/4.png')
    // },
    // {
    //     key: "202411261",
    //     tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/3-1.png')
    // },
    // {
    //     key: "202409200",
    //     tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发2",
    //     date: "2024年9月20日",
    //     image: require('@/assets/images/news/first.png')
    //     // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
    // },
    // {
    //     key: "202411260",
    //     tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/4.png')
    // },
    // {
    //     key: "202411261",
    //     tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/3-1.png')
    // },
    // {
    //     key: "202409200",
    //     tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发3",
    //     date: "2024年9月20日",
    //     image: require('@/assets/images/news/first.png')
    //     // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
    // },
    // {
    //     key: "202411260",
    //     tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/4.png')
    // },
    // {
    //     key: "202411261",
    //     tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/3-1.png')
    // },
    // {
    //     key: "202409200",
    //     tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发4",
    //     date: "2024年9月20日",
    //     image: require('@/assets/images/news/first.png')
    //     // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
    // },
    // {
    //     key: "202411260",
    //     tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/4.png')
    // },
    // {
    //     key: "202411261",
    //     tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/3-1.png')
    // },
    // {
    //     key: "202409200",
    //     tittle: "天行启势 铸大国重器——宝信软件全栈自主可控PLC产品隆重首发5",
    //     date: "2024年9月20日",
    //     image: require('@/assets/images/news/first.png')
    //     // image: "http://127.0.0.1:9090/news-images/12/2/news/first.png"
    // },
    // {
    //     key: "202411260",
    //     tittle: "宝信软件闪耀2024国家工业软件大会，iPlat-BA荣获创新大奖",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/4.png')
    // },
    // {
    //     key: "202411261",
    //     tittle: "固本强基|曾俊伟自动化工作室开展宝信天行PLC技术交流会",
    //     date: "2024年11月26日",
    //     image: require('@/assets/images/news/detail/3-1.png')
    // },
]

export function getNewsByKey(key){
  for (let i = 0; i < newsData.length; i++) {
    if(newsData[i].key === key){
        return newsData[i]
    }
  }
}
export function getAll(){
  return newsData
}
