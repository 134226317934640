<template>
  <div class="newsCenter">
    <el-card class="newsCenter_card">
      <div class="topDiv"/>
      <el-card class="news_card">
        <template #header>
          <div class="card-header">
            <span>新闻中心</span>
          </div>
        </template>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="重点新闻" name="first">
            <el-row :gutter="23">
              <el-col v-for="(item,index) in newsDisplay" :span="8">
                <div class="contentDiv" @click="gotoDetails(index)">
                  <img class="img" alt="" :src="item.image">
                  <div class="news-tittle">
                    <span>
                       {{item.tittle}}
                    </span>
                  </div>
                  <div class="news-date">
                    <span>
                      {{ item.date }}
                    </span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="look-more-wrapper" id="more" v-if="isButtonDisplay">
              <el-button @click="lookMore">查看更多</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="展会信息" name="second">
          </el-tab-pane>
          <el-tab-pane label="研发成果" name="third">
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-card>
  </div>
</template>

<script>
import {getAll} from '@/mock/news'
export default {
  name: 'NewsCenterView',
  components: {
  },
  data() {
    return {
      activeName: 'first',
      news: [],
      newsDisplay: []
    }
  },
  computed:{
    isButtonDisplay(){
      if(this.news.length > 9){
        return true
      }else {
        return false
      }
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    fetchData(){
      this.news = getAll()
      if(this.news.length <= 9){
        this.newsDisplay = this.news
      }else {
        this.newsDisplay = this.news.slice(0,9)
      }
    },
    lookMore(){
      this.newsDisplay = this.news
      document.getElementById("more").style.visibility = "hidden"
    },
    gotoDetails(param){
      switch (param) {
        case 0:
          localStorage.setItem("newsKey",this.news[param].key)
          this.$router.push(
              {
                name: "detail",
                params: {
                  newsKey: this.news[param].key
                }
              }
          )
          break
        case 1:
          localStorage.setItem("newsKey",this.news[param].key)
          this.$router.push(
              {
                name: "winner",
                params: {
                  newsKey: this.news[param].key
                }
              }
          )
          break
        case 2:
          localStorage.setItem("newsKey",this.news[param].key)
          this.$router.push(
              {
                name: "subHeadingNews",
                params: {
                  newsKey: this.news[param].key
                }
              }
          )
          break
        default:
          break
      }
    }
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="less">
@import "@/assets/css/publicStyle.css";
.newsCenter{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #22262D;

  .newsCenter_card{
    width: 1440px;
    min-height: 1080px;
    background: #22262D;
    border:0px;
    box-shadow: none !important;
    border-top: 1px solid rgba(35, 44, 58, 0.2);
    --el-card-padding: 0px 64px;
    --el-card-border-radius: 0px;
    .el-card__body{
      .topDiv{
        width: 1312px;
        height: 267px;
        border-radius: 10px;
        background: ~"url(@/assets/images/news/banner.png)";
        background-size: 100% 100%;
        margin-top: 24px;
      }
      .news_card{
        width: 1312px;
        background: #22262D;
        margin-top: 20px;
        border:0px;
        box-shadow: none !important;
        --el-card-padding: 0px;
        --el-card-border-radius: 0px;
        .el-card__header{
          height: 41px;
          border-bottom:0px;
          margin-bottom: 32px;
          .card-header{
            text-align: center;
            span{
              font-family: Source Han Sans;
              font-size: 28px;
              font-weight: bold;
              line-height: 41px;
              color: #FAFAFC;
            }
          }
        }
        .el-card__body{
          width: 100%;
          .el-tabs{
            margin-top: 24px;
            .el-tabs__header{
              border-bottom:0px;
              .el-tabs__nav-wrap{
                .el-tabs__nav-scroll{
                  margin-left: 427px;
                  .el-tabs__nav{
                    border:0px;
                    :nth-child(1),:nth-child(2){
                      margin-right: 24px;
                    }
                    .el-tabs__item{
                      width: 136px;
                      height: 42px;
                      font-family: Source Han Sans;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 42px;
                      color: #FAFAFC;
                      border:0px;
                      padding: 0;
                      border-radius: 42px;
                      &:hover {
                        background-color: rgba(1, 174, 227, 0.4)!important;
                        color: #FAFAFC;
                      }
                      &.is-active {
                        background: rgba(250, 250, 252, 0.1);
                      }
                    }
                  }
                }
              }
            }
            .el-tabs__content{
              .el-tab-pane{
                width: 100%;
                margin-top: 32px;
                .el-row{
                  .contentDiv{
                    width: 427px;
                    height: 460px;
                    margin-bottom: 48px;

                    border-radius: 10px;
                    padding: 0;
                    background: rgba(29, 29, 31, 0.2);
                    .img{
                      width: 427px;
                      height: 282px;
                    }
                  }
                  .contentDiv:hover{
                    cursor:pointer;
                    opacity: 0.7;
                  }
                }
                .look-more-wrapper{
                  display: flex;
                  justify-content: center;
                  margin-bottom: 92.5px;

                  .el-button{
                    border-radius: 36px;
                    opacity: 1;

                    /* 自动布局 */
                    display: flex;
                    flex-direction: column;
                    padding: 8px 24px;

                    /* 背景-瓦黛蓝 */
                    background: #232C3A;

                    box-sizing: border-box;
                    /* 主渐变色 */
                    border: 1px solid rgb(0, 189, 192);
                    //border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 1;

                    font-family: Source Han Sans;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;

                    font-variation-settings: "opsz" 0.7;
                    /* 最浅 */
                    color: #FAFAFC;
                  }
                }
              }
            }
            .news-tittle{
              height: 105px;
              margin-top: 30px;
              margin-bottom: 16px;
              span{
                opacity: 1;
                /* 标题中 */
                font-family: Source Han Sans;
                font-size: 24px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0em;

                font-variation-settings: "opsz" 0.7;
                /* 最浅 */
                color: #FAFAFC;
              }
            }
            .news-date{
              width: 379px;
              height: 17px;
              opacity: 1;
              span{
                /* 补充文字中 */
                font-family: Source Han Sans;
                font-size: 12px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: 0em;

                font-variation-settings: "opsz" 0.7;
                /* 最浅 */
                color: #FAFAFC;

                z-index: 1;
              }
            }
          }
        }
      }
    }

  }
}
</style>
