<template>
  <div class="wrapper">
    <el-card class="support_card">
      <div class="topDiv">
        <div class="topContentWrapper">
        </div>
      </div>
      <el-card class="download_card">
        <template #header>
          <div class="card-header">
            <span>产品资料</span>
          </div>
        </template>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="T3" name="first">
            <el-row>
              <el-table :data="list"  class="table" height="608.5">
                <el-table-column prop="fileName" label="文件名" align="left" width="400"/>
                <el-table-column prop="type" label="资料分类" align="center" width="172.5"/>
                <el-table-column prop="version" label="版本号" align="center" width="152.5"/>
                <el-table-column prop="size" label="文件大小" align="center" width="152.5"/>
                <el-table-column align="center" label="操作" width="212.5">
                  <template v-slot="scope">
                    <el-button style="margin-right: 24px"
                               class="preview"
                               v-if="canPreview(scope.row)"
                               @click="previewNewWindow(scope.row)">预览</el-button>
                    <el-button class="download" @click="downloadFile(scope.row)">下载</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page">
                <el-pagination background
                               :page-size="pageSize"
                               :current-page = "currentPage"
                               layout="pager"
                               :total="total"
                               @current-change="handleCurrentChange"/>
              </div>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="T4" name="second">
            <el-row>
              <el-table :data="list" class="table" height="608.5">
                <el-table-column prop="fileName" label="文件名" align="left" width="400"/>
                <el-table-column prop="type" label="资料分类" align="center" width="172.5"/>
                <el-table-column prop="version" label="版本号" align="center" width="152.5"/>
                <el-table-column prop="size" label="文件大小" align="center" width="152.5"/>
                <el-table-column align="center" label="操作" width="212.5">
                  <template v-slot="scope">
                    <el-button style="margin-right: 24px"
                               class="preview"
                               v-if="canPreview(scope.row)"
                               @click="previewNewWindow(scope.row)">预览</el-button>
                    <el-button class="download" @click="downloadFile(scope.row)">下载</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page">
                <el-pagination background
                               :page-size="pageSize"
                               :current-page = "currentPage"
                               layout="pager"
                               :total="total"
                               @current-change="handleCurrentChange"/>
              </div>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="IDE" name="third">
            <el-row>
              <el-table :data="list"  class="table" height="608.5">
                <el-table-column prop="fileName" label="文件名" align="left" width="400"/>
                <el-table-column prop="type" label="资料分类" align="center" width="172.5"/>
                <el-table-column prop="version" label="版本号" align="center" width="152.5"/>
                <el-table-column prop="size" label="文件大小" align="center" width="152.5"/>
                <el-table-column align="center" label="操作" width="212.5">
                  <template v-slot="scope">
                    <el-button style="margin-right: 24px"
                               class="preview"
                               v-if="canPreview(scope.row)"
                               @click="preview(scope.row,'mp4')">预览</el-button>
                    <el-button class="manual" v-if="scope.row.suffix === 'manual'" @click="gotoManual">跳转</el-button>
                    <el-button class="download" v-if="scope.row.suffix !== 'manual'" @click="downloadFile(scope.row)">下载</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page">
                <el-pagination background
                               :page-size="pageSize"
                               :current-page = "currentPage"
                               layout="pager"
                               :total="total"
                               @current-change="handleCurrentChange"/>
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <el-card class="foot">
        <template #header>
          <div class="card-header">
            <span>联系我们</span>
          </div>
        </template>
        <div class="tel-wrapper">
          <span style="margin-right: 40px">热线电话：400-821-0860</span>
          <span>产品邮箱：baosky@baosight.com</span>
        </div>
      </el-card>
    </el-card>
    <!-- 预览对话框 -->
    <el-dialog class="preview-dialog"
               v-model="dialogVisible"
               :title="dialogTittle"
               width="835"
               draggable
               destroy-on-close
               center>
      <div class="pdf" v-if="previewType === 'pdf'">
        <PreviewPDF :url="previewUrl"/>
      </div>
      <div class="mp4" v-if="previewType === 'mp4'">
        <PreviewMP4 :url="previewUrl"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getAll} from '@/api/file'
import PreviewPDF from "@/components/preview/PreviewPDF.vue";
import PreviewMP4 from "@/components/preview/PreviewMP4.vue";
import {ElMessage} from "element-plus";
export default {
  name: "ServiceAndSupport",
  components: {
    PreviewMP4,
    PreviewPDF
  },
  inject: ['baseUrl'],
  data() {
    return {
      activeName: 'first',
      pageSize: 12,
      t3TotalPage: 0,
      t4TotalPage: 0,
      IDETotalPage: 0,
      currentPage: 1,
      total: 0,
      list: [],
      queryParams:{
        page: "",
        pageNum: 1,
        pageSize: 12,
      },
      dialogVisible: false,
      previewType: "",
      previewBaseUrl: this.baseUrl + "/files/preview/",
      downloadUrl: this.baseUrl + "/files/download/",
      previewUrl: "",
      dialogTittle: ""
    }
  },
  methods: {
    handleClick(tab, event) {
      let tabName = tab.paneName
      if(tabName === 'first'){
        this.queryParams.pageNum = 1
        this.currentPage = 1
        this.fetchData('t3')
      }else if(tabName === 'second'){
        this.queryParams.pageNum = 1
        this.currentPage = 1
        this.fetchData('t4')
      }else {
        this.queryParams.pageNum = 1
        this.currentPage = 1
        this.fetchData('IDE')
      }
    },
    gotoDetails(){
      this.$router.push({
        name:'detail',
      })
    },
    downloadFile(item){
      let link = document.createElement('a');
      link.href = this.downloadUrl + item.fileName
      link.download = item.fileName//下载后文件名
      document.body.appendChild(link);
      link.click();//点击下载
      link.remove();//下载完成移除元素
      window.URL.revokeObjectURL(link.href); //用完之后使用URL.revokeObjectURL()释放
    },
    preview(item,type){
      this.previewUrl = this.previewBaseUrl + item.fileName
      this.previewType = type
      this.dialogVisible = true
      this.dialogTittle = item.fileName
    },
    previewNewWindow(item){
      window.open(this.previewBaseUrl + item.fileName)
    },
    fetchData(param){
      this.queryParams.page = param
      getAll(this.queryParams).then((response) => {
        if(response === undefined) return
        if(response.data.code === 0){
          this.list = response.data.rows
          this.list.map((item) => {
            item.suffix = this.getExtension(item.fileName)
            if(item.suffix.charAt(0) !== '.'){
              item.suffix = "manual"
            }
          })
          this.total = response.data.total
        }
      }).catch(() => {
        ElMessage.error("加载数据失败")
      })
    },
    getExtension (name) {
      return name.substring(name.lastIndexOf("."))
    },
    canPreview(item){
      return item.suffix === '.pdf' || item.suffix === '.mp4'
    },
    gotoManual(){
      window.open("https://baosky.baosight.com/usermanual/")
    },
    handleCurrentChange(val){
      this.currentPage = val
      this.queryParams.pageNum = val
      if(this.activeName === 'first'){
        this.fetchData('t3')
      }
      if(this.activeName === 'second'){
        this.fetchData('t4')
      }
      if(this.activeName === 'third'){
        this.fetchData('IDE')
      }
    }
  },
  mounted() {
    //获取页面名称
    let pageName = this.$route.query.pageName;
    if(pageName === undefined){
      this.fetchData('t3');
    }
    if(pageName === "T3"){
      this.activeName="first";
      this.fetchData('t3');
    }
    else if(pageName === "T4"){
      this.activeName="second";
      this.fetchData('t4');
    }
    else if(pageName === "IDE"){
      this.activeName="third";
      this.fetchData('IDE');
    }
  },
}
</script>

<style lang="less">
@import "@/assets/css/publicStyle.css";
.wrapper{
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #22262D;
  overflow: scroll;

  .support_card{
    width: 1440px;
    padding: 0;
    background: #22262D;
    border:0px;
    box-shadow: none !important;
    border-top: 1px solid rgba(35, 44, 58, 0.2);
    --el-card-padding: 0px 64px;
    --el-card-border-radius: 0px;
    .topDiv{
      width: 1359px;
      height: 308px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      .topContentWrapper{
        width: 1312px;
        height: 267px;
        background: url(@/assets/images/support/top.png) no-repeat center;
      }

    }
    .el-card__body{
      .tittle{
        width: 140px;
        height: 41px;
        margin-left: 609.5px;
        margin-top: 64px;
        margin-bottom: 22px;
        span{
          opacity: 1;

          /* 标题大 */
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: normal;
          text-align: center;
          letter-spacing: 0em;

          font-variation-settings: "opsz" 0.7;
          /* 最浅 */
          color: #FAFAFC;
        }
      }
      .download_card{
        width: 1090px;
        height: 837.5px;
        //height: 765.5px;
        margin-left: 134.5px;
        background: #22262D;
        margin-top: 49px;
        border:0px;
        box-shadow: none !important;
        --el-card-padding: 0px;
        --el-card-border-radius: 0px;
        .el-card__header{
          height: 35px;
          border-bottom:0px;
          margin-bottom: 48px;
          .card-header{
            text-align: center;
            span{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 35px;
              color: #FAFAFC;
            }
          }
        }
        .el-card__body{
          width: 100%;
          height: 754.5px;
          //height: 682.5px;
          margin-top: 48px;
          .el-tabs{
            height: 100%;
            margin-top: 24px;
            .el-tabs__header{
              height: 40px;
              border: none;
              .el-tabs__nav-wrap{
                height: 100%;
                .el-tabs__nav-scroll{
                  height: 100%;
                  .el-tabs__nav{
                    width: 457px;
                    height: 100%;
                    margin-left: 316.5px;
                    border:0px;
                    :nth-child(1),:nth-child(2){
                      margin-right: 48px;
                    }
                    .el-tabs__item{
                      width: 118px;
                      height: 38px;
                      opacity: 1;

                      /* 自动布局 */
                      display: flex;
                      flex-direction: column;
                      padding: 6px 48px;
                      background: #232C3A;

                      box-sizing: border-box;
                      border: 1px solid rgb(0, 220, 158);
                      /* 主渐变色 */
                      //border-image: linear-gradient(90deg, #0095EE 0%, #00E09A 100%) 1;
                      //clip-path: inset(0 round 4px);
                      border-radius: 36px;

                      font-family: Source Han Sans;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 42px;
                      color: #FAFAFC;
                    }
                    .is-active{
                      background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                      /* 描边 - 百分 20 浅 */
                      border: 1px solid rgba(250, 250, 252, 0.2);
                      border-radius: 36px;
                    }
                  }
                }
              }
            }
            .el-tabs__content{
              .el-tab-pane{
                width: 100%;
                height: 680.5px;
                //height: 608.5px;
                margin-top: 12px;
                .el-row{
                  height: 100%;
                  .el-table{
                    width: 1090px;
                    --el-table-border-color: none;
                    --el-table-row-hover-bg-color: rgba(165, 165, 182, 0.5);
                    --el-table-border: none;
                    background: none;
                    .el-table__header-wrapper{
                      width: 100%;
                      .el-table__header{
                        thead {
                          background: none;
                          font-family: Source Han Sans;
                          font-size: 14px;
                          font-weight: bold;
                          line-height: normal;
                          text-align: center;
                          letter-spacing: 0em;

                          font-variation-settings: "opsz" 0.7;
                          /* 最浅 */
                          color: #FAFAFC;
                          tr{
                            background: none;
                            th{
                              background: none;
                            }
                          }
                        }
                      }
                    }
                    .el-table__body-wrapper{
                      tr{
                        background: none;
                        height: 20px;
                        opacity: 1;

                        /* 补充文字大 */
                        font-family: Source Han Sans;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: normal;
                        letter-spacing: 0em;

                        font-variation-settings: "opsz" 0.7;
                        /* 最浅 */
                        color: #FAFAFC;

                        .el-button{
                          background: none;
                          border: none;
                          padding-left: 3px;
                          /* 正文 */
                          font-family: Source Han Sans;
                          font-size: 16px;
                          font-weight: normal;
                          line-height: normal;
                          letter-spacing: 0em;

                          font-variation-settings: "opsz" 0.7;
                          /* 主色蓝-苍穹蓝 */
                          color: #0095EE;
                        }
                        .el-button:hover{
                          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                        }
                        .el-button:before{
                          content: '';
                          display: inline-block;
                          height: 18px;
                          margin-right: 4px;
                        }
                        .preview:before{
                          width: 21.64px;
                          background: url("@/assets/images/support/button/preview.png") no-repeat;
                          background-size: 100% 100%;
                        }
                        .download:before{
                          width: 20px;
                          background: url("@/assets/images/support/button/download.png") no-repeat;
                          background-size: 100% 100%;
                        }
                        .manual:before{
                          height: 16px;
                          width: 20px;
                          background: url("@/assets/images/support/button/go.png") no-repeat;
                          background-size: 100% 100%;
                        }
                      }
                    }
                  }

                  .page{
                    height: 72px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .el-pagination{
                      height: 32px;
                      margin-top: 40px;
                      ul{
                        li{
                          width: 32px;
                          height: 32px;
                          border-radius: 2px;
                          box-sizing: border-box;
                          /* 描边 - 百分 20 浅 */
                          border: 1px solid rgba(250, 250, 252, 0.2);
                          opacity: 1;

                          /* 自动布局 */
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          padding: 8px 18px;
                          margin-left: 16px;
                          background: none;
                          /* 补充文字中 */
                          font-family: Source Han Sans;
                          font-size: 12px;
                          font-weight: normal;
                          line-height: normal;
                          letter-spacing: 0em;

                          font-variation-settings: "opsz" 0.7;
                          /* 最浅 */
                          color: #FAFAFC;
                        }
                        :first-child {
                          margin-left: 0;
                        }
                        .is-active{
                          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                        }
                      }

                    }
                  }
                }
              }
            }
          }
        }
      }

      .foot{
        width: 1090px;
        height: 180px;
        margin-left: 134.5px;
        background: none;
        margin-top: 35px;
        margin-bottom: 54.5px;
        border:0px;
        box-shadow: none !important;
        --el-card-padding: 0px;
        --el-card-border-radius: 0px;
        .el-card__header{
          height: 52px;
          border-bottom:0px;
          .card-header{
            text-align: center;
            span{
              font-family: Source Han Sans;
              font-size: 24px;
              font-weight: bold;
              line-height: 52px;
              color: #FAFAFC;
            }
          }
        }
        .el-card__body{
          overflow: hidden;
          margin-top: 46px;
          width: 100%;
          height: 85px;
          padding: 0;
          /* 最浅 */
          color: #FAFAFC;
          .tel-wrapper{
            background: rgba(29, 29, 31, 0.2);
            width: 1088px;
            height: 79px;
            line-height: 79px;
            text-align: center;
            border-radius: 10px;
            /* 描边 - 百分 20 浅 */
            border: 1px solid rgba(250, 250, 252, 0.2);
            span{
              height: 79px;
              opacity: 1;
              /* 正文 */
              font-family: Source Han Sans;
              font-size: 16px;
              font-weight: normal;
            }
          }

        }
      }
    }


  }

  .preview-dialog{
    --el-dialog-bg-color: rgba(250, 250, 252, 0.225);

    .el-dialog__header{
      span{
        color:white;
      }
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: white;
    }
  }
}
</style>
