import request from '../utils/request'// 这里是引入咱们的axios用axios来进行异步接口请求
export function getAll(param){
    return request({
        url:'/files/listByPage',
        method:'get',
        params: param
    })
}

export function previewFile(fileName){
    return request({
        url:'/files/preview/' + fileName,
        method:'get',
        responseType: "blob"
    })
}

export function downloadFile(fileName){
    return request({
        url:'/files/download/' + fileName,
        method:'get',
        responseType: "blob"
    })
}
